import { makeAutoObservable, action } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';
import incomeServices, {
  AddWithdrawalConfirmParams,
  GetAddWithdrawalCreatorParams,
  SearchCreatorListParams,
} from '@/services/incomeServices';
import {
  TaxManageInfoType,
  TaxManageListParamsType,
  WithdrawalsListParamsType,
  UpdateWithdrawalsInfoParamsType,
  SearchCreatorListType,
  ExportSettlementInfoParams,
  RoadCreatorListType,
  WithdrawalsListType,
} from './types';
import { RoadUserInfoType } from '../RoadStore/types';
// import roadServices from '@/services/roadServices';

export default class IncomeStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this 绑定当前store
    makePersistable(this, {
      name: 'IncomeStore',
      properties: [],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  // creator uid
  uid: number = 0;
  setUid(uid: number) {
    this.uid = uid;
  }

  // creator info
  creatorInfo: RoadCreatorListType = {
    avatar: '',
    nickname: '',
    opPlatNum: 0,
    uid: 0,
    role: '',
    isDel: false,
  };

  setCreatorInfo(info: RoadCreatorListType) {
    this.creatorInfo = info;
  }

  // 扣税管理数据
  taxManageDataList: TaxManageInfoType[] = [];
  setTaxManageDataList(list: TaxManageInfoType[]) {
    this.taxManageDataList = list;
  }

  // 获取扣税管理列表
  async getTaxManageList() {
    this.taxManageDataList = [];
    const res = await incomeServices?.getTaxManageList();
    this.setTaxManageDataList(res.list);
    return Promise.resolve(res);
  }

  // 更新扣税管理(新增，修改)
  async updateTaxManageList(params: TaxManageListParamsType) {
    const res = await incomeServices?.updateTaxManageList(params);
    return Promise.resolve(res);
  }

  // 提现列表数据
  withdrawalsListData: WithdrawalsListType = {
    count: 0,
    items: [],
  };
  setWithdrawalsListData(list: WithdrawalsListType) {
    this.withdrawalsListData = list;
  }
  clearWithdrawalsListData() {
    this.withdrawalsListData = {
      count: 0,
      items: [],
    };
  }

  // 获取提现列表
  async getWithdrawalsList(params: WithdrawalsListParamsType) {
    const res = await incomeServices?.getWithdrawalsList(params);
    this.setWithdrawalsListData(res);
    return Promise.resolve(res);
  }

  // 获取提现详情
  async getWithdrawals(id: number) {
    const res = await incomeServices?.getWithdrawals(id);
    return Promise.resolve(res);
  }

  // 更新提现信息
  async updateWithdrawalsInfo(params: UpdateWithdrawalsInfoParamsType) {
    const res = await incomeServices?.updateWithdrawalsInfo(params);
    return Promise.resolve(res);
  }

  // 获取用户银行卡信息
  // async getUserBank(uid: number) {
  //   const res = await incomeServices?.getUserBank(uid);
  //   return Promise.resolve(res);
  // }

  basicInfo: RoadUserInfoType = {
    email: '',
    nickname: '',
    phone: '',
    realName: '',
  };

  setBasicInfo(res: RoadUserInfoType) {
    this.basicInfo = res;
  }

  // 获取达人基本信息
  // async getBasicInfo(uid: number) {
  //   const res = await roadServices.getBasicInfo(uid);
  //   this.setBasicInfo(res);
  //   return Promise.resolve(res);
  // }

  // 根据字段查询收益管理的达人列表
  async getWithdrawalCreatorList(status?: SearchCreatorListType) {
    const res = await incomeServices.getWithdrawalCreatorList(status);
    return Promise.resolve(res);
  }

  // 搜索收益管理达人
  async searchWithdrawalCreator(params: SearchCreatorListParams) {
    const res = await incomeServices.searchWithdrawalCreator(params);
    return Promise.resolve(res);
  }

  // 导出结算信息
  async exportSettlementList(params: ExportSettlementInfoParams) {
    const res = await incomeServices.exportSettlementList(params);
    return Promise.resolve(res);
  }

  // 新增提现 搜索UID
  async getAddWithdrawalCreator(uid: number) {
    const params: GetAddWithdrawalCreatorParams = {
      uid,
    };
    const res = await incomeServices.getAddWithdrawalCreator(params);
    return Promise.resolve(res);
  }

  // 新增提现 确认提现
  async addWithdrawalConfirm(uid: number) {
    const params: AddWithdrawalConfirmParams = {
      uid,
    };
    const res = await incomeServices.addWithdrawalConfirm(params);
    return Promise.resolve(res);
  }
}
